
import { defineComponent } from 'vue'
import { Project } from '@/types/project'

export default defineComponent({
  props: {
    projects: Array
  },
  emits: [
    'onEdit',
    'onDelete',
    'onRestoration'
  ],
  setup(props, context) {
    const handleEdit = (project: Project) => {
      context.emit('onEdit', project);
    }
    const handleDelete = (project: Project) => {
      context.emit('onDelete', project);
    }
    const handleRestoration = (project: Project) => {
      context.emit('onRestoration', project);
    }

    return {
      handleEdit,
      handleDelete,
      handleRestoration
    };
  },
})
